import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private scrollTargetSource = new BehaviorSubject<string>('');

  scrollTarget$ = this.scrollTargetSource.asObservable();

  setScrollTarget(target: string) {
    this.scrollTargetSource.next(target);
  }

  clearScrollTarget() {
    this.scrollTargetSource.next('');
  }
}
